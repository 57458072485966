import React from 'react';
import ReactDOM from 'react-dom';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const pixel_result = (pvalue) =>{
  return (
    <b>{pvalue}</b>
  )
}

const handleInputChange = event => {
  event.preventDefault();
  ReactDOM.render(0, document.getElementById('result_width'))
  ReactDOM.render(0, document.getElementById('result_height'))
}

const handleSubmit = event => {
  event.preventDefault();
  const target = event.target
  const mm_per_dpi = 25.4
  const pixel_width = (target.width.value/mm_per_dpi) * target.dpi.value
  const pixel_height = (target.height.value/mm_per_dpi) * target.dpi.value
  ReactDOM.render(pixel_result(Math.round(pixel_width)), document.getElementById('result_width'))
  ReactDOM.render(pixel_result(Math.round(pixel_height)), document.getElementById('result_height'))  
}

const Services = (props) => {
const json = props.data.allColorsJson.edges;
  return (
    <Layout bodyClass="page-services">
      <SEO title="Farbmuster" 
        description="Bildgrößenrechner zur Umrechnung von Millimetern zu Pixel mit Erklärungen zur verschiedenen Qualitäten und DPI."
      />
      <div className="strip strip-white strip-diagonal no-strip-mobile">
      <div className="intro mini-intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Farbmuster</h1>
              <p className="d-md-none">Zum Ändern nach links oder rechts wischen.</p>
              <p className="d-none d-md-inline">Zum Ändern links oder rechts klicken.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row justify-content-between">
        <div className="content col-12">
        <Carousel
            swipeable = {true}
            emulateTouch
            showThumbs = {false}
            showIndicators = {false}
        >
        {json.map(edge => (
            <div key={"wrapper-" + edge.node.name}>
                <div style={{backgroundColor: edge.node.color, height: "450px"}} key={"colordiv-" + edge.node.name}>&nbsp;</div>
                <p className="legend" key={"legend-" + edge.node.name}>{edge.node.name}</p>
            </div>
        ))}
            </Carousel>
            <p>Angezeigte Farben entsprechen nicht hundertprozentig den realen Farben. Bitte Displayhelligkeit hoch stellen!</p>
        <div className="col-12 col-lg-8 mt-2">
        <h2>Einsatz für Farbmuster</h2>
        <p>Sie möchten per Telefon oder Email eine Beschriftung in einer bestimmten Farbe bestellen oder anfragen? Dann 
            sollten wir über die gleiche Farbe sprechen. Es gibt so viele Abstufungen bei Farben das unser Verständnis ohne korrekte Angaben schnell auseinander geht, 
            und eine für Ihre Vorstellungen falsche Farbe genutzt wird.
        </p>
        <p>Deshalb können Sie hier bequem eine Farbe für Ihren Einsatzzweck aussuchen und uns die Farbnummer und Bezeichnung mitteilen. 
            So wissen wir immer genau welche Farbe Sie meinen</p>
        <p>Es kann bei der Darstellung der Farben zu kleinen Abweichungen zur tatsächlichen Farbe der Beschriftung geben, 
            da Farben auf elektronischen Displays immer etwas anders dargestellt werden.</p>
            </div>
        </div>
      </div>
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allColorsJson {
      edges {
        node {
          color
          name
        }
      }
    }
  }
`;

export default Services;
